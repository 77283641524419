import { css } from "styled-components"

export const feed = css`

  section {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;

    @media (max-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .blog-feed-item {

    flex-basis: 257px;
    margin-bottom: 56px;
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 34px;
    }

    @media (max-width: 768px) {
      margin-left: 15px;
      flex-basis: 90%;
      max-width: 375px;
    }

    &__box {

      height: 257px;
      background-color: #F5F5F5;
      position: relative;
      display: block;

      &:after,
      &:before {
        content: '';
        background: #48E4B7;
        position: absolute;
      }
      &:after {
        width: 100%;
        height: 10px;
        left: -5px;
        bottom: 0;
        transform: translatey(100%) skewx(-45deg);
      }
      &:before {
        width: 10px;
        height: 100%;
        left: 0;
        transform: translatex(-100%) skewy(-45deg);
        top: 5px;
      }
    }

    &__title {
      font-size: 24px;
      margin-top: 20px;
      max-width: 210px;

      @media (max-width: 768px) {
        max-width: 375px;
      }
    }
  }

`
