import React from "react"
import { graphql } from "gatsby"

import BlogPage, { BlogFeedDataPropsI } from "../pages_/blog/index.page"

export default (props: BlogFeedDataPropsI) => (

  <BlogPage 
    {...props}
  />

)

export const allPostsQuery = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          id
          slug
          path
          title
          status
          template
          format
          featured_media {
            source_url
          }
        }
      }
    }
  }
`