import React from "react"
import { Link } from "gatsby"

import { MainLayout } from "../../layouts/main.layout"
import StandardPageHeader from "../../components/layout-components/top/headers/standard-page-header/StandardPageHeader.component"
import PageSection from "../../layouts/PageSection/PageSection.component"
import CenterContainer from "../../layouts/containers/CenterContainer/CenterContainer.component"
import MainCtaButton from "../../components/layout-components/_/buttons/MainCtaButton/MainCtaButton.component"
import Footer from "../../components/layout-components/bottom/footer/Footer.component"

import { feedIntro } from "../../pages_/blog/css/feedIntro.css"
import { feed } from "../../pages_/blog/css/feed.css"
import HireUs from "../../components/layout-components/_/other/HireUs/HireUs.component"
import { getStaticImgUrl } from "../../utils/getStaticImgUrl"
import { getCanonicalUrl } from "../../utils/getCanonicalUrl"
import { replaceHtmlEntities } from "../../utils/replaceHtmlEntities"

// @TODO - simplify / flatten this so that it doesn't have "node" and/or passes it directly to template below.
interface wpPostNodeI {
  node: {
    id: string,
    slug: string,
    path: string,
    status: string,
    format: string,
    title: string,
    featured_media: {
      source_url: string
    }
  }
}

export interface BlogFeedDataPropsI {
  data: {
    allWordpressPost: {
      edges: Array<wpPostNodeI>
    }
  }
}

const meta = {
  title: "Blog feed",
  description: "Let's learn and grow together"
}

const og = {
  image: getStaticImgUrl("img_guest_bloggign_full.jpg")
}

const pageSlug = "blog";

const BlogFeed = (props: BlogFeedDataPropsI) => {

  const posts: wpPostNodeI[] = props.data.allWordpressPost.edges;

  return (

    <MainLayout meta={meta} og={og} canonicalUrl={getCanonicalUrl(pageSlug)}>



      {/* 
      
            Header

      */}

      <StandardPageHeader 
        data={
          {
            heading: "Blog",
            subText: "Let's learn and grow together",
            backgroundImage: "/img_guest_bloggign_full.jpg"
          }
        } 
      />



      {/* 
      
            Blog feed intro texts

      */}

      <PageSection extendStyles={feedIntro}>
          
        <div className="texts">

          <p className="texts--left bold">Want to stay up-to-date with the latest trends in online content marketing, blogging, and branding?</p>

          <p className="texts--right">Need a few pointers to help out your writing or some guidelines on what great content looks like? Maybe you’re just curious about what a business can get from written content?</p>

        </div>

      </PageSection>



      {/* 
    
            Blog feed

      */}

      <PageSection extendStyles={feed}>

        {
          posts.map((post: wpPostNodeI) => (
          
            <div className="blog-feed-item">

              <Link 
                to={`/blog/${post.node.slug}`}
                className="blog-feed-item__box" 
                style={{
                  backgroundImage: `url(${post.node.featured_media.source_url})`,
                  backgroundSize: 'cover'
                }}
              >
              </Link>
  
              <Link to={`/blog/${post.node.slug}`}>
                <h5 className="blog-feed-item__title">
                  {replaceHtmlEntities(post.node.title)}
                </h5>
              </Link>
  
            </div>
            
          ))
        }
      
    
      </PageSection>



      {/* 
      
            "Hire us"

      */}

      <HireUs />


      {/* 
      
            Footer

      */}

      <Footer />


    </MainLayout>

  )

}

export default BlogFeed;