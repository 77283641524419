import { css } from "styled-components"

export const feedIntro = css`

  /* @TODO - Extract this, it's repeated already. */
  .texts {

    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    p { 
      font-size: 24px;
      line-height: 34px;
    }

    &--left {
      margin-right: 30px;
      max-width: 552px;

      @media (max-width: 768px) {
        margin-right: initial;
        margin-bottom: 30px;
      }
    }

    &--right {
      max-width: 543px;
    }

  }

`
